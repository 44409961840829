<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ name }}</b-card-title>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-bar-chart
        v-if="boomboom != null"
        :height="400"
        :data="boomboom2"
        :options="boomboom"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'

import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    ChartjsComponentBarChart,
  },
  props: {
    tabvisite: {
      type: Array,
      default: () => [],
    },
    tabdate: {
      type: Array,
      default: () => [],
    },
    maxvisite: {
      type: Number,
      default: () => [],
    },
    name: {
      type: String,
      default: () => [],
    },
  },
  data() {
    return {
      boomboom: null,
      boomboom2: null,
      chartjsData,
      chartColors: {
        primaryColorShade: '#836AF9',
        yellowColor: '#ffe800',
        successColorShade: '#28dac6',
        warningColorShade: '#ffe802',
        warningLightColor: '#FDAC34',
        infoColorShade: '#299AFF',
        greyColor: '#4F5D70',
        blueColor: '#2c9aff',
        blueLightColor: '#84D0FF',
        greyLightColor: '#EDF1F4',
        tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        lineChartPrimary: '#666ee8',
        lineChartDanger: '#ff4961',
        labelColor: '#6e6b7b',
        grid_line_color: 'rgba(200, 200, 200, 0.2)',
      },
    }
  },
  watch: {
    maxvisite(val) {
      this.boomboom = {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: this.chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: this.chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
              ticks: {
                stepSize: 100,
                min: 0,
                max: val,
                fontColor: this.chartColors.labelColor,
              },
            },
          ],
        },
      }
    },
    tabdate(val) {
      this.boomboom2 = {
        labels: val,
        datasets:
          [
            {
              data: this.tabvisite,
              backgroundColor: '#28dac6',
              borderColor: 'transparent',
            },
          ],
      }
    },
    tabvisite(val) {
      this.boomboom2 = {
        labels: this.tabdate,
        datasets:
          [
            {
              data: val,
              backgroundColor: '#28dac6',
              borderColor: 'transparent',
            },
          ],
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

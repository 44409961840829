<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          {{ name }}
        </b-card-title>
      </div>
      <!--/ title and subtitle -->

      <!-- badge -->
      <!-- <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
        <h5 class="font-weight-bolder mb-0 mr-1">
          $ 100,000
        </h5>
        <b-badge variant="light-secondary">
          <feather-icon
            icon="ArrowDownIcon"
            size="16"
            class="text-danger mr-25"
          />
          <span class="align-middle">20%</span>
        </b-badge>
      </div> -->
      <!--/ badge -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        v-if="boomboom != null"
        type="line"
        height="400"
        :options="boomboom"
        :series="boomboom2"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
  },
  props: {
    tabcpc: {
      type: Array,
      default: () => [],
    },
    tabdate: {
      type: Array,
      default: () => [],
    },
    maxcpc: {
      type: Number,
      default: () => [],
    },
    name: {
      type: String,
      default: () => [],
    },
  },
  data() {
    return {
      apexChatData,
      boomboom: null,
      boomboom2: null,
    }
  },
  watch: {
    tabdate(val) {
      this.boomboom = {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          strokeColors: [$themeColors.light],
          colors: [$themeColors.warning],
        },
        colors: [$themeColors.warning],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50"><span>'}${
              data.series[data.seriesIndex][data.dataPointIndex]
            }€</span></div>`
          },
        },
        xaxis: {
          categories: val,
        },
        yaxis: {
          // opposite: isRtl,
        },
      }
    },
    tabcpc(val) {
      this.boomboom2 = [{
        data: val,
      }]
    },
  },
}
</script>

<template>
  <b-card :title="name">
    <app-echart-stacked-area
      v-if="option != null"
      :option-data="option"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'

export default {
  components: {
    BCard,
    AppEchartStackedArea,
  },
  props: {
    tabpaiementinitie: {
      type: Array,
      default: () => [],
    },
    tabachat: {
      type: Array,
      default: () => [],
    },
    tabcontact: {
      type: Array,
      default: () => [],
    },
    tabdate: {
      type: Array,
      default: () => [],
    },
    tabpanier: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: () => [],
    },
  },
  data() {
    return {
      option: null,
    }
  },
  watch: {
    tabpanier(val) {
      this.option = {
        xAxisData: this.tabdate,
        series: [
          {
            name: 'Panier',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            showSymbol: false,
            lineStyle: {
              width: 0,
            },
            data: val,
          },
          {
            name: 'Paiement',
            type: 'line',
            stack: 'Total',
            showSymbol: false,
            areaStyle: {},
            lineStyle: {
              width: 0,
            },
            data: this.tabpaiementinitie,
          },
          {
            name: 'Contact',
            type: 'line',
            stack: 'Total',
            showSymbol: false,
            areaStyle: {},
            lineStyle: {
              width: 0,
            },
            data: this.tabcontact,
          },
          {
            name: 'Achat',
            type: 'line',
            stack: 'Total',
            showSymbol: false,
            areaStyle: {},
            lineStyle: {
              width: 0,
            },
            data: this.tabachat,
          },
        ],
      }
    },
    tabachat(val) {
      this.option = {
        xAxisData: this.tabdate,
        series: [
          {
            name: 'Panier',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            showSymbol: false,
            lineStyle: {
              width: 0,
            },
            data: this.tabpanier,
          },
          {
            name: 'Paiement initié',
            type: 'line',
            stack: 'Total',
            showSymbol: false,
            areaStyle: {},
            lineStyle: {
              width: 0,
            },
            data: this.tabpaiementinitie,
          },
          {
            name: 'Contact',
            type: 'line',
            stack: 'Total',
            showSymbol: false,
            areaStyle: {},
            lineStyle: {
              width: 0,
            },
            data: this.tabcontact,
          },
          {
            name: 'Achat',
            type: 'line',
            stack: 'Total',
            showSymbol: false,
            areaStyle: {},
            lineStyle: {
              width: 0,
            },
            data: val,
          },
        ],
      }
    },
    tabcontact(val) {
      this.option = {
        xAxisData: this.tabdate,
        series: [
          {
            name: 'Panier',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            showSymbol: false,
            lineStyle: {
              width: 0,
            },
            data: this.tabpanier,
          },
          {
            name: 'Paiement initié',
            type: 'line',
            stack: 'Total',
            showSymbol: false,
            areaStyle: {},
            lineStyle: {
              width: 0,
            },
            data: this.tabpaiementinitie,
          },
          {
            name: 'Contact',
            type: 'line',
            stack: 'Total',
            showSymbol: false,
            areaStyle: {},
            lineStyle: {
              width: 0,
            },
            data: val,
          },
          {
            name: 'Achat',
            type: 'line',
            stack: 'Total',
            showSymbol: false,
            areaStyle: {},
            lineStyle: {
              width: 0,
            },
            data: this.tabachat,
          },
        ],
      }
    },
    tabpaiementinitie(val) {
      this.option = {
        xAxisData: this.tabdate,
        series: [
          {
            name: 'Panier',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            showSymbol: false,
            lineStyle: {
              width: 0,
            },
            data: this.tabpanier,
          },
          {
            name: 'Paiement initié',
            type: 'line',
            stack: 'Total',
            showSymbol: false,
            areaStyle: {},
            lineStyle: {
              width: 0,
            },
            data: val,
          },
          {
            name: 'Contact',
            type: 'line',
            stack: 'Total',
            showSymbol: false,
            areaStyle: {},
            lineStyle: {
              width: 0,
            },
            data: this.tabcontact,
          },
          {
            name: 'Achat',
            type: 'line',
            stack: 'Total',
            showSymbol: false,
            areaStyle: {},
            lineStyle: {
              width: 0,
            },
            data: this.tabachat,
          },
        ],
      }
    },
    tabdate(val) {
      this.option = {
        xAxisData: val,
        series: [
          {
            name: 'Panier',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            showSymbol: false,
            lineStyle: {
              width: 0,
            },
            data: this.tabpanier,
          },
          {
            name: 'Paiement initié',
            type: 'line',
            stack: 'Total',
            showSymbol: false,
            areaStyle: {},
            lineStyle: {
              width: 0,
            },
            data: this.tabpaiementinitie,
          },
          {
            name: 'Contact',
            type: 'line',
            stack: 'Total',
            showSymbol: false,
            areaStyle: {},
            lineStyle: {
              width: 0,
            },
            data: this.tabcontact,
          },
          {
            name: 'Achat',
            type: 'line',
            stack: 'Total',
            showSymbol: false,
            areaStyle: {},
            lineStyle: {
              width: 0,
            },
            data: this.tabachat,
          },
        ],
      }
    },
  },
}
</script>

<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          {{ name }}
        </b-card-title>
      </div>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-line-chart
        v-if="boomboom != null"
        :height="400"
        :data="boomboom2"
        :options="boomboom"
        :plugins="plugins"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import ChartjsComponentLineChart from './charts-components/ChartjsComponentLineChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    ChartjsComponentLineChart,
  },
  props: {
    tabroi: {
      type: Array,
      default: () => [],
    },
    tabdate: {
      type: Array,
      default: () => [],
    },
    maxroi: {
      type: Number,
      default: () => [],
    },
    name: {
      type: String,
      default: () => [],
    },
  },
  data() {
    return {
      boomboom: null,
      boomboom2: null,
      chartjsData,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
      chartColors: {
        primaryColorShade: '#836AF9',
        yellowColor: '#ffe800',
        successColorShade: '#28dac6',
        warningColorShade: '#ffe802',
        warningLightColor: '#FDAC34',
        infoColorShade: '#299AFF',
        greyColor: '#4F5D70',
        blueColor: '#2c9aff',
        blueLightColor: '#84D0FF',
        greyLightColor: '#EDF1F4',
        tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        lineChartPrimary: '#666ee8',
        lineChartDanger: '#ff4961',
        labelColor: '#6e6b7b',
        grid_line_color: 'rgba(200, 200, 200, 0.2)',
      },
    }
  },
  watch: {
    maxroi(val) {
      this.boomboom = {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        hover: {
          mode: 'label',
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: this.chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        layout: {
          padding: {
            top: -15,
            bottom: -25,
            left: -15,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                display: true,
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
              ticks: {
                fontColor: this.chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                stepSize: 100,
                min: 0,
                max: val,
                fontColor: this.chartColors.labelColor,
              },
              gridLines: {
                display: true,
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
            },
          ],
        },
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 9,
          },
        },
      }
    },
    tabdate(val) {
      this.boomboom2 = {
        labels: val,
        datasets: [
          {
            data: this.tabroi,
            label: 'ROI',
            borderColor: this.chartColors.lineChartDanger,
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: this.chartColors.lineChartDanger,
            fill: false,
            pointRadius: 6,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: this.chartColors.lineChartDanger,
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: this.chartColors.tooltipShadow,
          },
        ],
      }
    },
    tabroi(val) {
      this.boomboom2 = {
        labels: this.tabdate,
        datasets: [
          {
            data: val,
            label: 'ROI',
            borderColor: this.chartColors.lineChartDanger,
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: this.chartColors.lineChartDanger,
            fill: false,
            pointRadius: 6,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: this.chartColors.lineChartDanger,
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: this.chartColors.tooltipShadow,
          },
        ],
      }
    },
  },
}
</script>

<template>
  <section id="dashboard-ecommerce">
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="FacebookIcon" />
          <span>Facebook Ads</span>
        </template>
        <b-card>
          <h1 class="text-center">{{ data.name }}</h1>
          <div class="d-flex align-items-center">
            <feather-icon
              icon="CalendarIcon"
              size="16"
            />
            <flat-pickr
              v-model="rangePicker"
              :config="{ mode: 'range'}"
              class="form-control flat-picker bg-transparent border-0 shadow-none"
              placeholder="YYYY-MM-DD"
            />
          </div>
        </b-card>

        <b-row class="match-height">
          <!-- <b-col
            xl="4"
            md="6"
          >
            <ecommerce-medal :data="data.congratulations" />
          </b-col> -->
          <b-col
            xl="12"
            md="12"
          >
            <ecommerce-statistics
              name="Statistiques"
              :data="data.total1"
            />
          </b-col>
          <b-col
            xl="12"
            md="12"
          >
            <ecommerce-statistics
              name="Conversions"
              :data="data.total2"
            />
          </b-col>
          <b-col md="6">
            <chartjs-bar-chart
              v-if="data != {}"
              name="Visites"
              :tabdate="data.tab_date"
              :tabvisite="data.tab_visite"
              :maxvisite="data.max_visite"
            />
          </b-col>
          <b-col md="6">
            <chartjs-line-chart
              v-if="data != {}"
              name="ROI (€)"
              :tabdate="data.tab_date"
              :tabroi="data.tab_roi"
              :maxroi="data.max_roi"
            />
          </b-col>
          <b-col md="6">
            <apex-line-chart
              v-if="data != {}"
              name="Coût par clic (CPC)"
              :tabdate="data.tab_date"
              :tabcpc="data.tab_cpc"
              :maxcpc="data.max_cpc"
            />
          </b-col>
          <b-col md="6">
            <echart-line
              v-if="data != {}"
              name="Budget"
              :tabdate="data.tab_date"
              :tabbudget="data.tab_budget"
              :maxbudget="data.max_budget"
            />
          </b-col>
          <b-col md="12">
            <echart-stacked-area
              v-if="data != {}"
              name="Conversions"
              :tabdate="data.tab_date"
              :tabpanier="data.tab_panier"
              :tabpaiementinitie="data.tab_paiementInitie"
              :tabcontact="data.tab_contact"
              :tabachat="data.tab_achat"
            />
          </b-col>
          <b-col md="12">
            <ecommerce-company-table
              v-if="data != {}"
              name="Historique de vos campagnes"
              :table-data="data.campagne"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="GoogleIcon" />
          <span>Google Ads</span>
        </template>

        <vue-friendly-iframe width="100%" v-if="userData.googledatastudioid != null" :src="`https://datastudio.google.com/embed/reporting/`+userData.googledatastudioid+`/page/VgD`" @load="userData"></vue-friendly-iframe>
        <p v-else>Aucune donnée disponible.</p>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

import { BTabs, BTab, BCard, BRow, BCol } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import ChartjsBarChart from './ChartjsBarChart.vue'
import ChartjsLineChart from './ChartjsLineChart.vue'
// import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import ApexLineChart from './ApexLineChart.vue'
import EchartLine from './EchartLine.vue'
import EchartStackedArea from './EchartStackedArea.vue'

export default {
  components: {
    flatPickr,
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,

    ChartjsBarChart,
    ChartjsLineChart,
    // EcommerceMedal,
    EcommerceStatistics,
    EcommerceCompanyTable,
    ApexLineChart,
    EchartLine,
    EchartStackedArea,
  },
  data() {
    return {
      userData: {},
      data: {},
      rangePicker: [
        `${(new Date()).getFullYear()}-${String((new Date()).getMonth()).padStart(2, '0')}-${String((new Date()).getDate()).padStart(2, '0')}`, `${(new Date()).getFullYear()}-${String((new Date()).getMonth() + 1).padStart(2, '0')}-${String((new Date()).getDate()).padStart(2, '0')}`,
      ],
    }
  },
  watch: {
    rangePicker(val) {
      const data = val.split(' to ')
      this.$http.post('https://app2.oscar-black.com/api/is_it_working2', { data })
        .then(response => {
          this.data = response.data

          // ? Your API will return name of logged in user or you might just directly get name of logged in user
          // ? This is just for demo purpose
          // const userData = getUserData()
          // this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
        })
    },
  },
  created() {
    // data
    const data = this.rangePicker
    this.$http.post('https://app2.oscar-black.com/api/is_it_working2', { data })
      .then(response => {
        this.data = response.data

        this.userData = getUserData()
        console.log(this.userData)
        // this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

iframe {
  height: 900px;
  width: 100%;
}
</style>

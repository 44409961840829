<template>
  <b-card :title="name">
    <!-- <div class="d-flex justify-content-between flex-wrap">

      <div class="d-flex align-content-center mb-1 mb-sm-0">
        <h1 class="font-weight-bolder ">
          $ 183,382
        </h1>
        <div class="pt-25 ml-75">
          <b-badge variant="light-secondary">
            <feather-icon
              icon="ArrowDownIcon"
              size="12"
              class="text-danger align-middle"
            />
            <span class="font-weight-bolder align-middle"> 24%</span>
          </b-badge>
        </div>
      </div>
    </div> -->

    <!-- echart -->
    <app-echart-line
      v-if="option != null"
      :option-data="option"
    />

  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'

export default {
  components: {
    BCard,
    AppEchartLine,
  },
  props: {
    tabbudget: {
      type: Array,
      default: () => [],
    },
    tabdate: {
      type: Array,
      default: () => [],
    },
    maxbudget: {
      type: Number,
      default: () => [],
    },
    name: {
      type: String,
      default: () => [],
    },
  },
  data() {
    return {
      option: null,
    }
  },
  watch: {
    maxbudget() {
      this.option = {
        xAxisData: this.tabdate,
        series: this.tabbudget,
      }
    },
    tabdate() {
      this.option = {
        xAxisData: this.tabdate,
        series: this.tabbudget,
      }
    },
    tabbudget() {
      this.option = {
        xAxisData: this.tabdate,
        series: this.tabbudget,
      }
    },
  },
}
</script>
